<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
          <QuickTranslation
            translation-key="documentDescription.label"
            tag="h1"
          />
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="documentDescriptions"
      class=" agTable"
      :no-results-text="$t('common.ui.not_search_found')"
      :no-data-text="$t('common.ui.not_data_found')"
      :loading-text="$t('common.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('common.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
           onkeypress="return (event.charCode != 34)" style="max-width: 25em;"
           v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
            color="primary"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('common.titles.filter')"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                tile
                outlined
                color="primary"
                class="mb-2"
                v-on="on"
              >
                <v-icon small color="primary">mdi-plus</v-icon>
                {{ $t("common.buttons.new") }}
              </v-btn>
            </template>
            <v-card :light="true" color="white">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-alert
                    outlined
                    type="warning"
                    prominent
                    color="#E8BD30"
                    border="left"
                    :value="!isExist"
                  >
                    El tipo de documento seleccionado no posee aún compañías
                    para asignar dicha descripción, seleccione otro.
                  </v-alert>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceDocumentDescriptionName" v-on:keyup.ctrl.86="replaceDocumentDescriptionName"
                          color="primary"
                          v-model="editedItem.document_description_name"
                          :label="$t('documentDescription.fields.name')"
                          required
                          class="required"
                          :rules="requireAndMaxRules(max)"
                          :counter="max"
                          maxlength="max"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox :no-data-text="$t('common.fields.noDataAvailable')"
                          color="primary"
                          :clearable="true"
                          :hide-selected="true"
                          :items="documentTypes"
                          :label="$t('documentDescription.fields.type')"
                          :small-chips="false"
                          item-text="document_type_name"
                          return-object
                          item-value="id"
                          persistent-hint
                          v-model="editedItem.documentType"
                          required
                          class="required"
                          :rules="requireRules"
                          @change="getCompanies($event ? $event : undefined)"
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <select-companies
                          :companies="companies ? companies : []"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small tile outlined color="gray" @click="close">
                  {{ $t("common.buttons.cancel") }}
                </v-btn>
                <v-btn
                  small
                  tile
                  outlined
                  color="primary"
                  :disabled="!valid"
                  @click="save"
                >
                  {{ $t("common.buttons.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon color="neuter" small class="mr-2" @click="editItem(item)"
          >mdi-pencil</v-icon
        >
        <v-icon color="neuter" small @click="deleteItemDialog(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn small text color="primary" @click="deleteItem">
              {{ $t("common.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import DocumentDescriptionService from "../../../services/DocumentDescriptionService";
import LanguajeService from "../../../services/LanguajeService";
import SelectCompanies from "../../../components/SelectCompanies";
import _ from "lodash";

export default {
  components: { SelectCompanies },
  data: () => ({
    dialog: false,
    editedIndex: -1,
    search: "",
    editedItem: {
      document_description_name: "",
      documentType: null,
      companies: [],
    },
    defaultItem: {
      document_description_name: "",
    },
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    valid: false,
    max: 40,
    deletingItem: {},
    companies: [],
    isExist: true,
  }),

  computed: {
    selectedCompanies: {
      get() {
        return this.$store.getters["company/selected"];
      },
      set(val) {
        this.$store.dispatch("company/selectedCompanies", val);
      },
    },
    ...mapGetters({
      documentDescriptions: "document_description/documentDescriptions",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      documentTypes: "document_type/documentTypes",
      languageStructure: "general/languageStructure",
    }),
    headers: () => [
      {
        text: i18n.t("documentDescription.fields.name"),
        value: "document_description_name",
      },
      {
        text: i18n.t("common.fields.action"),
        value: "action",
        width: 100,
        sortable: false,
        align: "end",
      },
    ],
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("common.titles.new")
          : i18n.t("common.titles.edit")) +
        " " +
        i18n.t("documentDescription.label")
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      fetchListDocumentTypesByCompany:
        "document_type/fetchListDocumentTypesByCompany",
      fetchListDocumentDescriptions:
        "document_description/fetchListDocumentDescriptions",
      /*fetchListDocumentTypes: 'document_type/fetchListDocumentTypes',*/
      saveDocumentDescriptions: "document_description/saveDocumentDescriptions",
    }),
    initialize() {
      this.fetchListDocumentTypesByCompany([
        JSON.parse(localStorage.getItem("profile")).company_id,
        this.$toast,
      ]);
      this.fetchListDocumentDescriptions(this.$toast);
      /*this.fetchListDocumentTypes(this.$toast);*/
    },

    editItem(item) {
      this.editedIndex = this.documentDescriptions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedCompanies = this.editedItem.companies;
      this.dialog = true;
    },

    deleteItem() {
      this.dialogClose();
      DocumentDescriptionService.delete(this.deletingItem.id)
        .then((resp) => {
          this.$toast.success(
            this.$t("documentDescription.notification.deleted"),
            {
              icon: 'mdi-check-circle',
              queueable: true
            }
          );
        })
        .catch((err) => {
          if (err.message.includes("406")) {
            this.$toast.info(
              this.$t("documentDescription.notification.inUse"),
              {
                icon: 'mdi-information-slab-circle',
                queueable: true
              }
            );
          } else {
            this.$toast.error(
              this.$t("documentDescription.notification.deletedError"),
              {
                queueable: true,
              }
            );
          }
        })
        .finally(() => {
          this.fetchListDocumentDescriptions([this.$toast]);
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.selectedCompanies = [];
        this.isExist = true;
        this.editedItem.documentType = [];
      }, 300);
    },

    save() {
      let item = Object.assign({}, this.editedItem);
      item.companies = this.selectedCompanies;
      // Translate
      item.language_key = LanguajeService.setKey3(
        item.document_description_name,
        item.language_key,
        !item.id ? this.languageStructure : null
      );
      // *****
      delete item["document_description_name"];
      this.saveDocumentDescriptions([
        item,
        this.editedIndex,
        this.$toast,
      ]).finally(() => {
        this.fetchListDocumentDescriptions([this.$toast]);
        this.$toast.success(i18n.t("documentDescription.notification.save"), {
          queueable: true
        });
        this.close();
      });
    },

    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },

    dialogClose() {
      this.showDialog = false;
    },

    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },

    getCompanies(event) {
      if (event && event.companies) {
        this.companies = event.companies;
        this.isExist = true;
      } else if (event === undefined || event.companies === null) {
        this.companies = [];
        this.isExist = true;
      } else {
        this.companies = [];
        this.isExist = false;
      }
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceDocumentDescriptionName(event){
      this.editedItem.document_description_name =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
};
</script>

<style scoped></style>
